<!--
 * @Autor: oops.liu
-->
<template>
  <div class="coupon_item flex_sb" v-if="info">
    <div class="coupon_left">
      <div class="count" v-if="info.type === '1'">
        <span class="tabs">￥</span>{{ info.reduceAmount }}
      </div>
      <div class="count" v-else-if="info.type === '2'">
        {{ info.discount }} <span class="tabs">折</span>
      </div>
      <p class="hint">满{{ info.premiseAmount }}可用</p>
    </div>
    <div class="coupon_right">
      <div class="coupon_title">优惠券</div>
      <div class="btns">
        <div class="btn" v-if="info.hasReceive">点击领取</div>
        <div class="btn" v-else>可用商品</div>
      </div>
      <img
        v-if="!info.hasReceive"
        class="receive_icon"
        src="~@/assets/img/shop/receive.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.coupon_item {
  position: relative;
  width: 120px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(~@/assets/img/shop/coupon.png);
  // overflow: hidden;
  border-radius: 6px;
  .coupon_left {
    height: 100%;
    width: 75px;
    height: 100%;
    color: #fff;
    text-align: center;
    overflow: hidden;
    .count {
      padding-top: 10px;
      width: 100%;
      font-size: 16px;
      .tabs {
        display: inline-block;
        font-size: 12px;
      }
    }

    .hint {
      font-size: 12px;
      white-space: nowrap;
      transform: scale(0.8);
      transform-origin: 50% 0%;
    }
  }
  .coupon_right {
    flex: 1;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
    .coupon_title {
      padding-top: 10px;
      color: #4a9ae5;
      font-size: 12px;
      transform: scale(0.9);
    }
    .btns {
      width: 100%;
      white-space: nowrap;
      .btn {
        width: 100%;
        display: block;
        font-size: 12px;
        transform: scale(0.7);
        border-radius: 5px;
        background: #4a9ae5;
        color: #fff;
        padding: 0 5px;
        transform-origin: 30% 50%;
      }
    }
    .receive_icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
