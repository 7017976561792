<!--
 * @Autor: oops.liu
 图片类标题组件
-->
<template>
  <div class="banner_title" v-if="cover">
    <img :style="{height:height}" :src="cover" alt="" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    cover: {
      required: true,
    },
    height: {
      type: String,
      default: "14px",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.banner_title {
  text-align: center;
  
}
</style>
