<!--
 * @Autor: oops.liu
 优惠券
-->
<template>
  <div class="coupon">
    <div class="left">
      <div class="content">
        <div v-if="info.type === '1'">
          {{ info.reduceAmount }}<span>元</span>
        </div>
        <div v-else-if="info.type === '2'">
          {{ info.discount }} <span>折</span>
        </div>
      </div>
      <div class="hint">满{{ info.premiseAmount }}可用</div>
    </div>
    <div class="right">
      <div class="top">
        <p class="tags">{{ info.type === "1" ? "代金券" : "折扣券" }}</p>
        {{ info.name }}
      </div>
      <div class="bottom flex_sb">
        <p class="time text_hide">
          {{ info.receiveBeginTime | formatTime }}-{{
            info.receiveEndTime | formatTime
          }}
        </p>

        <div class="slot_box">
          <slot :info="info"></slot>
        </div>
      </div>
      <img
        v-if="!info.hasReceive"
        class="receive_icon"
        src="~@/assets/img/shop/receive.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  filters: {
    formatTime(time) {
      const t = dayjs(time).format("YYYY-MM-DD");
      return t;
    },
  },
};
</script>

<style scoped lang="scss">
.coupon {
  height: 100px;
  padding: 5px;
  position: relative;
  background-image: url("~@/assets/img/shop/coupon_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .left {
    text-align: center;
    width: 33%;
    white-space: nowrap;
    .content {
      font-size: 24px;
      color: #fff;
      padding: 10px 0;
      span {
        font-size: 14px;
      }
    }
    .hint {
      font-size: 12px;
      color: #fff;
      display: inline-block;
    }
  }
  .right {
    flex: 1;
    box-sizing: border-box;
    padding: 7px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      font-size: 14px;
      color: $main;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      .tags {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        background: #4a9ae5;
        border-radius: 10px;
        padding: 4px;
      }
    }
    .bottom {
      flex: inherit;
      align-items: flex-end;
      .time {
        flex: 1;
        font-size: 13px;
        color: $main;
      }
      .slot_box {
        // .receive-btn {
        //   height: 24px;
        //   background: #f85353;
        //   border-radius: 12px;
        //   border-color: #f85353;
        //   font-size: 12px;
        //   color: #fff;
        //   padding: 0 12px;
        // }
        // .complete-btn {
        //   color: #f85353;
        //   border-color: #f85353;
        //   border-radius: 12px;
        //   height: 24px;
        //   font-size: 12px;
        //   padding: 0 12px;
        // }
      }
    }
    .receive_icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 55px;
      height: 55px;
    }
  }
}
</style>
