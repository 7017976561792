<!--
 * @Autor: oops.liu
-->
<template>
  <scroller
    ref="my_scroll"
    :noDataText="noDataText"
    :on-infinite="infinite"
    :on-refresh="refresh"
  >
    <div class="recommend">
      <!-- 优惠券 -->
      <div class="coupon_box">
        <van-swipe :loop="false" :width="130" :show-indicators="false">
          <van-swipe-item v-for="item in couponList" :key="item.id">
            <coupon-item :info="item" @click.native="handleCoupon(item)" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 轮播图 -->
      <div class="banner">
        <van-swipe height="453" indicator-color="#fff" :autoplay="3000">
          <van-swipe-item v-for="item in sellerInfo.couponImg" :key="item">
            <img :src="item" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="page_title">
        <banner-title :cover="banner_title" />
      </div>
      <div ref="goodsList" class="goods_list flex_sb">
        <goods-item
          :info="item"
          height="170px"
          v-for="item in listData"
          :key="item.id"
          @jump="handleGoods"
        >
          <p class="evaluate">500+条评价 98%好评</p>
        </goods-item>
      </div>
    </div>
  </scroller>
</template>

<script>
import { mapState, mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import couponItem from "./couponmin.vue";
import bannerTitle from "components/common/bannerTitle.vue";
import goodsItem from "components/common/goodsItem.vue";
import getTokenMixin from "@/mixin/getToken.js";
export default {
  name: "recommend",
  components: { couponItem, bannerTitle, goodsItem },
  mixins: [scrollMethods, handleGoods, getTokenMixin],
  props: {},
  data() {
    return {
      coupon: {
        id: 1,
        price: 30,
        desc: "满300元可用",
        receive: true,
      },
      banner_title: require("@/assets/img/shop/ban_title.png"),
    };
  },
  computed: {
    ...mapState({
      sellerInfo: (state) => state.seller.sellerInfo,
      couponList: (state) => state.seller.couponList,
    }),
    shopId() {
      return this.$route.query.shopId;
    },
  },
  methods: {
    ...mapActions({
      load: "seller/loadGoodsList",
      messageLoad: "seller/getMessageList",
      loadCouponList: "seller/getCouponList",
      getCoupon: "seller/getCoupon",
    }),
    async loadInfo(isRefresh = false) {
      const data = await this.load({
        shopId: this.shopId,
        current: this.pages,
        isHot: 1,
      });
      this.dataLength = data.records.length;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }
      return data;
    },
    handleCoupon(item) {
      // 判断领取优惠券
      if (item.hasReceive) {
        this.getCoupon(item.id).then(() => {
          this.loadCouponList(this.shopId);
        });
      } else {
        const scrollHeight =
          this.$refs.goodsList.getBoundingClientRect().top - 224;
        this.$refs.my_scroll.scrollTo(0, scrollHeight);
        console.log(scrollHeight);
      }
    },
    otherMethod() {
      this.messageLoad();
      this.loadCouponList(this.shopId);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.loadInfo();
    });
  },
};
</script>

<style scoped lang="scss">
.recommend {
  padding-top: 5px;
  width: 100%;

  .coupon_box {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 14px;
  }
  .banner {
    background: #f2f2f2;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .page_title {
    padding: 20px 0;
    background: #f2f2f2;
  }
  .goods_list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    flex-wrap: wrap;
    background: #f2f2f2;
    .evaluate {
      color: #999;
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
}
</style>
