<!--
 * @Autor: oops.liu
-->
<template>
  <scroller>
    <div class="news">
      <div class="message">
        <van-notice-bar
          :left-icon="icon"
          background="#fff"
          color="#333"
          text="在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。"
        />
      </div>
      <div class="news_content">
        <div class="news_title">上新日历</div>
        <div class="news_list">
          <p class="time">07月08日本店上新</p>
          <div class="goods_list flex_sb">
            <goods-item
              :info="item"
              height="170px"
              v-for="item in listData"
              :key="item.id"
              @jump="handleGoods"
            >
              <p class="evaluate">500+条评价 98%好评</p>
            </goods-item>
          </div>
        </div>
      </div>
    </div>
  </scroller>
</template>

<script>
import { mapState, mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import goodsItem from "components/common/goodsItem.vue";
import getTokenMixin from "@/mixin/getToken.js";
export default {
  name: "news",
  components: { goodsItem },
  mixins: [scrollMethods, handleGoods, getTokenMixin],
  props: {},
  data() {
    return {
      icon: require("@/assets/img/notice.png"),
    };
  },
  computed: {
    ...mapState({
      goods: (state) => state.app.goods,
    }),
    id() {
      return this.$route.query.shopId;
    },
  },
  methods: {
    ...mapActions({ load: "seller/loadGoodsList" }),
    async loadInfo(isRefresh = false) {
      const data = await this.load({ shopId: this.$route.params.shopId, current: this.pages });
      this.dataLength = data.records.length;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }

      return data;
    },
  },
 
  mounted() {
    this.$nextTick(() => {
    this.loadInfo();
    });
  },
};
</script>

<style scoped lang="scss">
.news {
  width: 100%;
  background: #f2f2f2;
  min-height: 60vh;
  box-sizing: border-box;
  padding-bottom: 100px;
  .message {
    box-sizing: border-box;
    padding: 0 14px;
    background: #fff;
  }
  .news_content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px;

    .news_title {
      padding: 10px 7px;
      font-size: 15px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 18px;
        background: #f00;
        transform: translateY(-50%);
      }
    }
    .news_list {
      .time {
        color: #666;
        font-size: 12px;
        padding-bottom: 13px;
        text-align: center;
        width: 100%;
      }
      .goods_list {
        flex-wrap: wrap;
        .evaluate {
          color: #999;
          font-size: 12px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
</style>
