<!--
 * @Autor: oops.liu
-->
<template>
  <scroller
    ref="my_scroll"
    :noDataText="noDataText"
    :on-infinite="infinite"
    :on-refresh="refresh"
  >
    <div class="goods">
      <order-bar @change="orderChange" @woned="handleWoned" />
      <div class="goods_list flex_sb">
        <goods-item
          :info="item"
          height="170px"
          v-for="item in listData"
          :key="item.id"
          @jump="handleGoods"
        >
          <p class="evaluate">500+条评价 98%好评</p>
        </goods-item>
      </div>
    </div>
  </scroller>
</template>

<script>
import { mapState, mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import orderBar from "./orderBar.vue";
import goodsItem from "components/common/goodsItem.vue";
import getTokenMixin from "@/mixin/getToken.js";
export default {
  name: "goods",
  components: { orderBar, goodsItem },
  mixins: [scrollMethods, handleGoods, getTokenMixin],
  props: {},
  data() {
    return {
      isHot: true,
      sortColumn: "",
      isAsc: false,
      asc: true,
    };
  },
  computed: {
    ...mapState({
      goods: (state) => state.app.goods,
      id() {
        return this.$route.query.shopId;
      },
    }),
  },
  methods: {
    ...mapActions({ load: "seller/loadGoodsList" }),
    async loadInfo(isRefresh = false) {
      const params = {
        shopId: this.$route.params.shopId,
        current: this.pages,
        isHot: 1,
        sortColumn: this.sortColumn,
        asc: this.asc,
      };
      if (!this.isHot) {
        delete params.isHot;
      }
      if (!this.sortColumn) {
        delete params.sortColumn;
      }
      if (!this.isAsc) {
        delete params.asc;
      }

      const data = await this.load({ ...params });
      this.dataLength = data.records.length;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }
      return data;
    },
    orderChange(item) {
      this.pages = 1;
      this.sortColumn = item.sortColumn;
      this.listData = [];
      this.isAsc = item.sortColumn === "price_down";
      this.asc = item.asc;
      this.isHot = item.sortColumn === "isHot";

      this.loadInfo();
    },
    handleWoned(value) {
      console.log("是否仅看有货：", value);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.loadInfo();
    });
  },
};
</script>

<style scoped lang="scss">
.goods {
  width: 100%;
  height: 100%;
  padding-top: 3px;
  padding-bottom: 20px;
  background: #f2f2f2;
  position: relative;
  transform: translate(0,0);
  .goods_list {
    width: 100%;
    box-sizing: border-box;
    padding-top: 5px;
    padding-left: 14px;
    padding-right: 14px;
    flex-wrap: wrap;
    .evaluate {
      color: #999;
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
}
</style>
