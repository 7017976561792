<template>
  <div class="home_page" @touchmove.prevent="">
    <top-head
      :sellerinfo="sellerInfo"
      @left="leftHandle"
      @right="rightHandle"
      @search="search"
      @details="details"
      @follow="follow"
      :badge="12"
    />
    <div class="home">
      <div class="page_tabs">
        <van-tabs
          v-model="active"
          color="#fff"
          background="transparent"
          title-inactive-color="#fff"
          title-active-color="#fff"
          @click="tabHandle"
        >
          <van-tab v-for="item in tabList" :key="item.id" :title="item.title" />
        </van-tabs>
      </div>
      <div class="tabs_content">
        <transition name="route-move" mode="out-in">
          <component :is="tab_active" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import topHead from "components/shop/topHead.vue";
import recommend from "./components/recommend.vue";
import goods from "./components/goods.vue";
import news from "./components/news.vue";
import activite from "./components/activite.vue";
import headEvent from "@/mixin/headEvent.js";

export default {
  components: { topHead, recommend, goods, news, activite },
  mixins: [headEvent],
  data() {
    return {
      value: "",
      bg: require("@/assets/img/shop/bg.png"),
      active: 0,
      tab_active: "recommend",
    };
  },
  computed: {
    ...mapState({
      sellerInfo: (state) => state.seller.sellerInfo,
    }),
    tabList() {
      const list = [
        {
          id: 0,
          title: "精选",
          name: "recommend",
        },
        {
          id: 1,
          title: "商品",
          name: "goods",
        },
        {
          id: 2,
          title: "上新",
          name: "news",
        },
        {
          id: 3,
          title: "活动",
          name: "activite",
        },
      ];
      return list;
    },
    id() {
      return this.$route.query.shopId;
    },
  },
  watch: {},
  methods: {
    // ...mapActions({
    //   loadAllCoupon: "seller/loadAllCoupon",
    // }),
    tabHandle(item) {
      this.tab_active = this.tabList[item].name;
    },

    load(info) {
      console.log("加载数据", info);
    },
  },
  created() {},
  mounted() {
    // 获取该店铺全部优惠券
    // this.loadAllCoupon({ shopId: this.id, size: 100 });
  },
};
</script>
<style scoped lang="scss">
.home_page {
  width: 100%;
  height: 100%;
  position: relative;
  .home {
    position: relative;
    z-index: 3;
    margin-top: -100px;
    .tabs_content {
      height: calc(100vh - 150px);
      position: relative;
      box-sizing: border-box;
    }
  }
}
</style>
