<!--
 * @Autor: oops.liu
-->
<template>
  <scroller ref="my_scroll">
    <div class="activite">
      <div class="common_box">
        <div class="banner">
          <banner-title :cover="banner1" />
        </div>
        <div class="coupon_domain">
          <template v-for="item in couponList">
            <coupon-item
              :key="item.id"
              :info="item"
              @click.native="handleCoupon(item)"
            >
              <van-button class="receive"
                ><span>{{
                  item.hasReceive ? "点击领取" : "立即使用"
                }}</span></van-button
              >
            </coupon-item>
          </template>
        </div>
      </div>
      <div class="activite_list common_box">
        <div class="banner banner2">
          <banner-title :cover="banner2" />
        </div>
        <div class="page_tab">
          <van-tabs
            v-model="active"
            :ellipsis="false"
            line-height="0"
            title-active-color="#FF0000"
            @change="tabChange"
          >
            <van-tab
              v-for="item in tabList"
              :key="item.index"
              :title="item.title"
            />
          </van-tabs>
          <div class="hint_box">
            <p class="time">促销时间：{{ hint_time }}</p>
            <p class="rule_hint">促销规则：{{ hint_rule }}</p>
          </div>
          <div class="goods_list flex_sb" ref="goodsList">
            <goods-item
              :info="item"
              height="170px"
              v-for="item in listData"
              :key="item.id"
              @jump="handleGoods"
            >
              <p class="evaluate">500+条评价 98%好评</p>
            </goods-item>
          </div>
        </div>
      </div>
    </div>
  </scroller>
</template>

<script>
import { mapState, mapActions } from "vuex";
import bannerTitle from "components/common/bannerTitle.vue";
import couponItem from "./couponItem.vue";
import goodsItem from "components/common/goodsItem.vue";
import handleGoods from "@/mixin/handleGoods.js";
import getTokenMixin from "@/mixin/getToken.js";
export default {
  name: "activite",
  components: { bannerTitle, couponItem, goodsItem },
  mixins: [handleGoods, getTokenMixin],
  props: {},
  data() {
    return {
      banner1: require("@/assets/img/shop/title1.png"),
      banner2: require("@/assets/img/shop/title2.png"),
      active: 1,
      tabList: [
        {
          index: 0,
          title: "满119减30元",
          time: "07月19日  23:59结束促销",
          rule: "满119.00减30.00元",
        },
        {
          index: 1,
          title: "满239减70元",
          time: "07月1日  23:59结束促销",
          rule: "满119.00减30.00元",
        },
        {
          index: 2,
          title: "满299减120元",
          time: "07月20日  23:59结束促销",
          rule: "满119.00减30.00元",
        },
        {
          index: 3,
          title: "1件打9折",
          time: "23:59结束促销",
          rule: "满119.00减30.00元",
        },
      ],
      hint_time: "",
      hint_rule: "",
      couList: [],
    };
  },
  computed: {
    ...mapState({
      couponList: (state) => state.seller.couponList,
      listData: (state) => state.seller.testList,
    }),
    shopId() {
      return this.$route.query.shopId;
    },
  },
  methods: {
    ...mapActions({
      messageLoad: "seller/getMessageList",
      loadCouponList: "seller/getCouponList",
      getCoupon: "seller/getCoupon",
    }),
    tabChange(index) {
      const item = this.tabList[index];
      this.hint_time = item.time;
      this.hint_rule = item.rule;
    },
    handleCoupon(item) {
      // 判断领取优惠券
      if (item.hasReceive) {
        this.getCoupon(item.id).then(() => {
          this.loadCouponList(this.shopId);
        });
      } else {
        const scrollHeight =
          this.$refs.goodsList.getBoundingClientRect().top - 224;
        this.$refs.my_scroll.scrollTo(0, scrollHeight);
        console.log(scrollHeight);
      }
    },

    otherMethod() {
      this.messageLoad();
      this.loadCouponList(this.shopId);
    },
  },

  mounted() {
    this.$nextTick(() => {
      const item = this.tabList[0];
      this.hint_time = item.time;
      this.hint_rule = item.rule;
    });
  },
 
};
</script>

<style scoped lang="scss">
.activite {
  box-sizing: border-box;
  padding: 14px;
  padding-bottom: 60px;
  .common_box {
    border-radius: 10px;
    overflow: hidden;
    background: #f7f6f6;
    margin-bottom: 10px;
    .banner {
      padding: 15px 0;
      background: #fff;
      margin-bottom: 2px;
    }
    .banner2 {
      margin-bottom: 0;
    }
    .coupon_domain {
      box-sizing: border-box;
      padding: 5px;
      .receive {
        font-size: 12px;
        color: #fff;
        background: #4a9ae5;
        border-radius: 10px;
        height: 19px;
        width: 60px;
        span {
          transform: scale(0.9);
        }
      }
      .received {
        color: #4a9ae5;
        background: #fff;
        border-color: #4a9ae5;
      }
    }
  }
  .activite_list {
    width: 100%;
    box-sizing: border-box;
    background: #f7f6f6;
    padding-bottom: 0;
    .hint_box {
      padding: 12px;
      background: #ffecb3;
      font-size: 12px;
      color: #ff8f00;
      .time {
        padding-bottom: 5px;
      }
    }
    .goods_list {
      padding: 10px 4px;
      flex-wrap: wrap;
      .evaluate {
        color: #999;
        font-size: 12px;
        padding-bottom: 5px;
      }
    }
  }
}
</style>
